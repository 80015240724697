import React, { useEffect, useState, useRef, useReducer } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMapEvents, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import Modal from 'react-modal';
import 'leaflet/dist/leaflet.css';
import './MapComponent.css';
import { parquetRead, parquetMetadata } from 'hyparquet'
import { rectangle, Draw } from 'leaflet';


const road_class_map = {
    '1': 'Interstate',
    '2': 'US Highway',
    '3': 'State Highway',
    '4': 'County Road',
    '5': 'City Street',
    '6': 'Other'
}

const minSegmentLength = 3

function milesToDegrees(miles, latitude) {
    // Constants for conversion
    const milesPerDegreeLatitude = 69; // Approximate value
  
    // Latitude conversion
    const degreesLatitude = miles / milesPerDegreeLatitude;
  
    // Longitude conversion (varies with latitude)
    const milesPerDegreeLongitude = milesPerDegreeLatitude * Math.cos(latitude * Math.PI / 180);
    const degreesLongitude = miles / milesPerDegreeLongitude;
  
    return degreesLongitude;
  }

function sigmoid(z, k) {
    return 2 * (1 / (1 + Math.exp(-z / k)) - 0.5);
}

const readData = async (setDataCallback) => {
    const res = await fetch('/final_data.parquet')
    const arrayBuffer = await res.arrayBuffer()
    const metadata = parquetMetadata(arrayBuffer)
    const colNames = metadata.schema.map(schemaElement => schemaElement.name).slice(1)

    await parquetRead({
        file: arrayBuffer,
        onComplete: data => {
            const highways = {};
            const totalCounts = {
                crashes: 0,
                crashes_alcohol: 0,
                crashes_drug: 0,
                crashes_hr: 0,
                crashes_mar: 0,
                crashes_sp: 0,
                volume: 0,
            }
            data.forEach((row) => {
                const rowObj = Object.fromEntries(row.map((value, index) => [colNames[index], value]));
                const highwayNumber = rowObj['HWYNUMB'];
                if (!highways[highwayNumber]) {
                    highways[highwayNumber] = [];
                }
                highways[highwayNumber].push(rowObj);
                totalCounts.crashes += rowObj.CR
                totalCounts.crashes_alcohol += rowObj.CR_ALC
                totalCounts.crashes_drug += rowObj.CR_DRUG
                totalCounts.crashes_hr += rowObj.CR_HR
                totalCounts.crashes_mar += rowObj.MAR
                totalCounts.crashes_sp += rowObj.CR_SP
                totalCounts.volume += rowObj.AADT
            });
            setDataCallback({ highways, totalCounts });
        }
    })
}


const reduceMapState = (state, action) => {
    switch (action.type) {
        case 'SET_FILTERS':  // city, county, roadClass, circle
            const filters = { ...state.filters, ...action };
            const filteredData = Object.values(state.mapData.highways).map((mpDataList) => {
                return mpDataList.filter((mpData) => {
                    //return (action.city === "" || mpData.CITYNAME.toLowerCase().includes(filters.city.toLowerCase())) &&
                    //    (action.county === "" || mpData.COUNTYNAME.toLowerCase().includes(filters.county.toLowerCase())) &&
                    //    (action.roadClass === "" || road_class_map[mpData.ROADCLASS].toLowerCase().includes(filters.roadClass.toLowerCase())) && 
                    return (filters.circle && Math.sqrt(Math.pow(mpData.LAT - filters.circle.latlng.lat, 2) + Math.pow(mpData.LONGTD - filters.circle.latlng.lng, 2)) < (filters.circle.radius))
                });
            });
            return { ...state, filters, filteredData };
        case 'SET_WEIGHT_PARAMS':
            return { ...state, weightParams: action.weightParams };
        case 'SET_MAP_DATA':
            return { ...state, mapData: {totalCounts: action.totalCounts, highways: action.highways} };
        case 'SET_SELECTED_SEGMENT':
            console.log(action.selectedSegment);
            return { ...state, selectedSegment: action.selectedSegment };
        default:
            return state;
    }
};

const initialState = {
    mapData: { totalCounts: { crashes: 0, crashes_alcohol: 0, crashes_drug: 0, crashes_hr: 0, crashes_mar: 0, crashes_sp: 0, volume: 0 }, highways: {} },
    filters: { city: '', county: '', roadClass: '' },
    filteredData: [],
    selectedSegment: null,
    weightParams: { drugUse: 2, speeding: 1, hitAndRun: 1.5 },
    circleParams: { latlng: { lat: 0, lng: 0 }, radius: 0 }
}


const MapComponent = () => {
    const [mapState, dispatch] = useReducer(reduceMapState, initialState);
    const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [circle, setCircle] = useState(null);

    useEffect(() => {
        // Load JSON data
        if (Object.keys(mapState.mapData.highways).length === 0)
            readData(({highways, totalCounts}) => dispatch({ type: 'SET_MAP_DATA', highways, totalCounts }));
    }, [mapState.mapData]);

    const getColor = (safetyScore) => {
        if (safetyScore === 0) return 'black';
        if (safetyScore < 10) return 'red';
        if (safetyScore < 60) return 'orange';
        return 'green';
    };

    const onEachFeatureVisible = (feature, layer) => {
        const safetyScore = feature.properties.safety_score;
        layer.setStyle({ color: getColor(safetyScore), opacity: 0.5, stroke: true, weight: 3 + 5 * sigmoid(feature.properties.average_daily_traffic, 5000) });
    };

    const onEachFeatureInvisible = (feature, layer) => {
        layer.setStyle({ stroke: true, color: 'transparent', opacity: 0, weight: 20 });
        layer.on('click', () => {
            dispatch({type: "SET_SELECTED_SEGMENT", selectedSegment: feature.properties});
            setModalIsOpen(true);
        });
    };

    const getNextMPList = (currentMP, mpDataList, index) => {
        const nextMPList = [];
        for (let i = index + 1; i < mpDataList.length; i++) {
            nextMPList.push(mpDataList[i]);
            if (mpDataList[i].MP - currentMP.MP > minSegmentLength) break;
        }
        return nextMPList;
    };

    const getHighwaySegments = (data) => {
        const highwaySegments = [];

        Object.values(data).forEach((mpDataList) => {
            mpDataList.sort((a, b) => a.MP - b.MP);
            let iSkipped = 0;
            mpDataList.forEach((_, index) => {
                if (index + iSkipped >= mpDataList.length - 1) return;
                const currentMP = mpDataList[index + iSkipped];
                const mpList = [currentMP, ...getNextMPList(currentMP, mpDataList, index + iSkipped)]
                iSkipped += mpList.length - 2
                const endMP = mpList[mpList.length - 1]
                const mpDistance = endMP.MP - currentMP.MP
                const volume = Math.max(...mpList.map(mp => mp.AADT))
                const roadDensity = mpDistance * (365 * volume)

                const crash_count = mpList.reduce((acc, mp) => acc + mp.CR, 0)
                const crash_count_alc = mpList.reduce((acc, mp) => acc + mp.CR_ALC, 0)
                const crash_count_drug = mpList.reduce((acc, mp) => acc + mp.CR_DRUG, 0)
                const crash_count_hr = mpList.reduce((acc, mp) => acc + mp.CR_HR, 0)
                const crash_count_mar = mpList.reduce((acc, mp) => acc + mp.CR_MAR, 0)
                const crash_count_sp = mpList.reduce((acc, mp) => acc + mp.CR_SP, 0)

                const crash_rate = (crash_count / roadDensity) || 0
                const crash_rate_alc = (crash_count_alc / roadDensity) || 0
                const crash_rate_drug = (crash_count_drug / roadDensity) || 0
                const crash_rate_hr = (crash_count_hr / roadDensity) || 0
                const crash_rate_mar = (crash_count_mar / roadDensity) || 0
                const crash_rate_sp = (crash_count_sp / roadDensity) || 0
                const risk_score = (crash_rate + mapState.weightParams.drugUse * crash_rate_alc + mapState.weightParams.drugUse * crash_rate_drug + mapState.weightParams.drugUse * crash_rate_mar + mapState.weightParams.hitAndRun * crash_rate_hr + mapState.weightParams.speeding * crash_rate_sp)


                let safety_score = 1
                if (risk_score !== 0) {
                    safety_score = 1 / risk_score
                }

                const properties = {
                    highway_name: currentMP.HWYNAME,
                    highway_number: currentMP.HWYNUMB,
                    start_mp: currentMP.MP,
                    end_mp: endMP.MP,
                    start_mp_desc: currentMP.DESC,
                    end_mp_desc: endMP.DESC,
                    crash_count: crash_count,
                    crash_count_alc: crash_count_alc,
                    crash_count_drug: crash_count_drug,
                    crash_count_hr: crash_count_hr,
                    crash_count_mar: crash_count_mar,
                    crash_count_sp: crash_count_sp,
                    crash_rate: 100 * sigmoid(crash_rate, 0.00001),
                    crash_rate_alc: 100 * sigmoid(crash_rate_alc, 0.00001),
                    crash_rate_drug: 100 * sigmoid(crash_rate_drug, 0.00001),
                    crash_rate_hr: 100 * sigmoid(crash_rate_hr, 0.00001),
                    crash_rate_mar: 100 * sigmoid(crash_rate_mar, 0.00001),
                    crash_rate_sp: 100 * sigmoid(crash_rate_sp, 0.00001),
                    safety_score: risk_score === 0 ? 100 : 100 * sigmoid(safety_score, 100000),
                    average_daily_traffic: volume,
                }

                for (let i = 0; i < mpList.length - 1; i++) {
                    const currentMP = mpList[i]
                    const nextMP = mpList[i + 1]
                    highwaySegments.push({
                        type: "Feature",
                        properties: { ...properties },
                        geometry: {
                            type: "LineString",
                            coordinates: [[currentMP.LONGTD, currentMP.LAT], [nextMP.LONGTD, nextMP.LAT]]
                        }
                    });
                }
            });
        });
        return highwaySegments
    };

    const renderVisibleHighwaySegments = (highwaySegments) => {
        return <GeoJSON key={"visible" + mapState.weightParams.toString() + highwaySegments.length} data={{ type: "FeatureCollection", features: highwaySegments }} onEachFeature={onEachFeatureVisible} />
    }

    const renderInvisibleHighwaySegments = (highwaySegments) => {
        return <GeoJSON key={"invisible" + mapState.weightParams.toString() + highwaySegments.length} data={{ type: "FeatureCollection", features: highwaySegments }} onEachFeature={onEachFeatureInvisible} />
    }

    const closeModal = () => {
        setModalIsOpen(false);
        dispatch({type: "SET_SELECTED_SEGMENT", selectedSegment: null});
    };

    const openFilterModal = () => {
        setFilterModalIsOpen(true);
    };

    const closeFilterModal = () => {
        setFilterModalIsOpen(false);
    };

    const getSummaryStats = (segments) => {
        // Calculate summary statistics asynchronously
        return {
            safety_score: (segments.reduce((acc, seg) => acc + seg.properties.safety_score, 0) / segments.length),
            crash_rate: (segments.reduce((acc, seg) => acc + seg.properties.crash_rate, 0) / segments.length),
            crash_rate_alc: (segments.reduce((acc, seg) => acc + seg.properties.crash_rate_alc, 0) / segments.length),
            crash_rate_drug: (segments.reduce((acc, seg) => acc + seg.properties.crash_rate_drug, 0) / segments.length),
            crash_rate_hr: (segments.reduce((acc, seg) => acc + seg.properties.crash_rate_hr, 0) / segments.length),
            crash_rate_mar: (segments.reduce((acc, seg) => acc + seg.properties.crash_rate_mar, 0) / segments.length),
            crash_rate_sp: (segments.reduce((acc, seg) => acc + seg.properties.crash_rate_sp, 0) / segments.length),
            crash_count: segments.reduce((acc, seg) => acc + seg.properties.crash_count, 0),
            crash_count_alc: segments.reduce((acc, seg) => acc + seg.properties.crash_count_alc, 0),
            crash_count_drug: segments.reduce((acc, seg) => acc + seg.properties.crash_count_drug, 0),
            crash_count_hr: segments.reduce((acc, seg) => acc + seg.properties.crash_count_hr, 0),
            crash_count_mar: segments.reduce((acc, seg) => acc + seg.properties.crash_count_mar, 0),
            crash_count_sp: segments.reduce((acc, seg) => acc + seg.properties.crash_count_sp, 0),
        };
    };

    const renderModalContent = () => {
        const selectedSegment = mapState.selectedSegment;
        if (!selectedSegment) return null;
        return (
            <div>
                <h2>{`Highway: ${selectedSegment.highway_name} (${selectedSegment.highway_number})`}</h2>
                <h4>
                    From milepoint {selectedSegment.start_mp} ({selectedSegment.start_mp_desc}) <br />
                    to {selectedSegment.end_mp} ({selectedSegment.end_mp_desc})
                </h4>
                <table>
                    <tbody>
                        <tr>
                            <td>Safety Score:</td>
                            <td><b>{selectedSegment.safety_score.toFixed(0)}</b> / 100</td>
                        </tr>
                        <br />
                        <tr>
                            <td>Crash risk score:</td>
                            <td><b>{selectedSegment.crash_rate.toFixed(0)}</b> / 100</td>
                        </tr>
                        <tr>
                            <td>Crash risk involving alcohol:</td>
                            <td><b>{selectedSegment.crash_rate_alc.toFixed(0)}</b> / 100</td>
                        </tr>
                        <tr>
                            <td>Crash risk involving marijuana:</td>
                            <td><b>{selectedSegment.crash_rate_mar.toFixed(0)}</b> / 100</td>
                        </tr>
                        <tr>
                            <td>Crash risk involving other drug use:</td>
                            <td><b>{selectedSegment.crash_rate_drug.toFixed(0)}</b> / 100</td>
                        </tr>
                        <tr>
                            <td>Crash risk involving speeding:</td>
                            <td><b>{selectedSegment.crash_rate_sp.toFixed(0)}</b> / 100</td>
                        </tr>
                        <tr>
                            <td>Crash risk involving hit and run incidents:</td>
                            <td><b>{selectedSegment.crash_rate_hr.toFixed(0)}</b> / 100</td>
                        </tr>
                        <br />
                        <tr>
                            <td>Total number of crashes:</td>
                            <td><b>{selectedSegment.crash_count}</b></td>
                        </tr>
                        <tr>
                            <td>Number of crashes involving alcohol:</td>
                            <td><b>{selectedSegment.crash_count_alc}</b></td>
                        </tr>
                        <tr>
                            <td>Number of crashes involving marijuana:</td>
                            <td><b>{selectedSegment.crash_count_mar}</b></td>
                        </tr>
                        <tr>
                            <td>Number of crashes involving other drug use:</td>
                            <td><b>{selectedSegment.crash_count_drug}</b></td>
                        </tr>
                        <tr>
                            <td>Number of crashes involving speeding:</td>
                            <td><b>{selectedSegment.crash_count_sp}</b></td>
                        </tr>
                        <tr>
                            <td>Number of crashes involving hit and run incidents:</td>
                            <td><b>{selectedSegment.crash_count_hr}</b></td>
                        </tr>
                        <tr>
                            <td>Average daily traffic:</td>
                            <td><b>{selectedSegment.average_daily_traffic.toFixed(0)}</b> vehicles</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const renderFilterModalContent = () => {
        return (
            <div>
                <h2>Filter and Adjust Risk Weights</h2>
                {/* Add filter options and sliders here */} 
                <p>City: <input type="text" placeholder="Enter city name" /></p>
                <p>County: <input type="text" placeholder="Enter county name" /></p>
                <p>Road Class: <input type="text" placeholder="Enter road class" /></p>
                <p>Alcohol Involved: <input type="range" min="0" max="100" /></p>
                <p>Speeding Involved: <input type="range" min="0" max="100" /></p>
                {/* Add more filters and sliders as needed */}
            </div>
        );
    };

    const renderSummaryModalContent = (filteredData) => {
        if (filteredData.length === 0) return null;
        const summaryStats = getSummaryStats(filteredData);
        return (
            <div>
            <table>
                <tbody>
                    <tr>
                        <td>Safety Score:</td>
                        <td><b>{summaryStats.safety_score.toFixed(0)}</b> / 100</td>
                    </tr>
                    <br />
                    <tr>
                        <td>Crash risk score:</td>
                        <td><b>{summaryStats.crash_rate.toFixed(0)}</b> / 100</td>
                    </tr>
                    <tr>
                        <td>Crash risk involving alcohol:</td>
                        <td><b>{summaryStats.crash_rate_alc.toFixed(0)}</b> / 100</td>
                    </tr>
                    <tr>
                        <td>Crash risk involving marijuana:</td>
                        <td><b>{summaryStats.crash_rate_mar.toFixed(0)}</b> / 100</td>
                    </tr>
                    <tr>
                        <td>Crash risk involving other drug use:</td>
                        <td><b>{summaryStats.crash_rate_drug.toFixed(0)}</b> / 100</td>
                    </tr>
                    <tr>
                        <td>Crash risk involving speeding:</td>
                        <td><b>{summaryStats.crash_rate_sp.toFixed(0)}</b> / 100</td>
                    </tr>
                    <tr>
                        <td>Crash risk involving hit and run incidents:</td>
                        <td><b>{summaryStats.crash_rate_hr.toFixed(0)}</b> / 100</td>
                    </tr>
                    <br />
                    <tr>
                        <td>Total number of crashes:</td>
                        <td><b>{summaryStats.crash_count}</b></td>
                    </tr>
                    <tr>
                        <td>Number of crashes involving alcohol:</td>
                        <td><b>{summaryStats.crash_count_alc}</b></td>
                    </tr>
                    <tr>
                        <td>Number of crashes involving marijuana:</td>
                        <td><b>{summaryStats.crash_count_mar}</b></td>
                    </tr>
                    <tr>
                        <td>Number of crashes involving other drug use:</td>
                        <td><b>{summaryStats.crash_count_drug}</b></td>
                    </tr>
                    <tr>
                        <td>Number of crashes involving speeding:</td>
                        <td><b>{summaryStats.crash_count_sp}</b></td>
                    </tr>
                    <tr>
                        <td>Number of crashes involving hit and run incidents:</td>
                        <td><b>{summaryStats.crash_count_hr}</b></td>
                    </tr>
                </tbody>
            </table>
            </div>
        );
    };

    let filteredSegments = [];
    if (mapState.filteredData.length > 0) {
        filteredSegments = getHighwaySegments(mapState.filteredData);
    }

    const highwaySegments = getHighwaySegments(mapState.mapData.highways);

    return (
        <div>
            <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css" />
            <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css" />
            <div className="button-container">
                {/*<button className="icon-button" onClick={openFilterModal} title="Map Options">
                    <img src="/icons/filter-icon.png" alt="Filter" />
                </button>*/}
            </div>
            <MapContainer center={[44, -120]} bounds={[[30]]} zoom={7} style={{ height: '95vh' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {renderVisibleHighwaySegments(highwaySegments)}
                {renderInvisibleHighwaySegments(highwaySegments)}
                <DrawControl mapState={mapState} dispatch={dispatch} />
            </MapContainer>
            <Modal
                appElement={document.getElementById('root')}
                isOpen={mapState.selectedSegment !== null}
                onRequestClose={closeModal}
                contentLabel="Segment Details"
                className="Modal"
                overlayClassName="Overlay"
            >
                {renderModalContent()}
            </Modal>
            {/*<Modal
                isOpen={filterModalIsOpen}
                onRequestClose={closeFilterModal}
                contentLabel="Filter and Adjust Risk Weights"
                className="Modal"
                overlayClassName="Overlay"
            >
                {renderFilterModalContent()()}
            </Modal>*/}
            <div className={`summary-modal ${mapState.filteredData.length > 0 ? 'visible' : ''}`}>
                {renderSummaryModalContent(filteredSegments)}
            </div>
        </div>
    );
};

const DrawControl = ({ mapState, dispatch }) => {
    return <FeatureGroup>
        <EditControl draw={{
            circle: !mapState.circle,
            marker: false,
            rectangle: false,
            polygon: false,
            polyline: false,
            circlemarker: false,
        }} position="topright" onCreated={drawCreatedEvent => {
            const layer = drawCreatedEvent.layer;
            const latlng = layer.getLatLng();
            dispatch({
                type: 'SET_FILTERS',
                circle: {
                    latlng: latlng,
                    radius: layer._radius / 111
                }
            })
        }} onDeleted={() => {
            dispatch({ type: 'SET_FILTERS', circle: null, filteredData: [] });
        }} />;
    </FeatureGroup>
};

export default MapComponent;
