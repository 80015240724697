import React from 'react';
import MapComponent from './MapComponent';
import './App.css';

const App = () => {
    return (
        <div className="app-root">
            <div className='app-header'>
                <h1>Oregon Highway Safety Map (2017-2022)</h1>
            </div>
            <div className="app-body">
                <MapComponent />
            </div>
        </div>
    );
};

export default App;
